.Config-measure {
  display: flex;
  justify-content: center;
}

.Config-measure-x,
.Config-measure-y,
.Config-measure-z {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Config-measure-wrap {
  display: flex;
}
.Config-measure-z input {
  max-width: 80px;
}
.hidden {
  display: none !important;
}
.Config-measure input {
  max-width: 80px;
}

.Config-measure-input-div {
  display: flex;
  flex-direction: column;
}

.Config-measure-input-div p {
  font-weight: 600;
  width: 80px;
}

.Config-measure-table-val {
  padding-left: 20px;
  background-color: #f4f4f4;
}

.Config-measure-ov td {
  border: 1px solid #ccc;
}
.Config-measure-ov {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}
.railchoice {
  display: flex;

  justify-content: center;
}

.railchoice button {
  width: 150px;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.railchoice-head {
  text-align: center;
}

.underchoice-btn img {
  width: 120px;
  height: 120px;
}
.morebtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.underchoice {
  display: flex;
  justify-content: center;
}
.underchoice button {
  margin: 10px;
}

.color {
  display: flex;
  justify-content: center;
}

.glassfill {
  display: flex;
  justify-content: center;
}

.glassfill button {
  margin: 10px;
}
.railingtop {
  display: flex;
  justify-content: center;
}
.railingtop button {
  margin: 10px;
}

.canvasdiv {
  position: relative;
}

#SetMeasure1 {
  position: absolute;
  z-index: 100;
  left: 0px;
}
#SetMeasure2,
#SetAngle1,
#SetAngle2,
#SetAngle3 {
  position: absolute;
}

#SetMeasure1 input,
#SetMeasure2 input,
#SetMeasure3 input,
#SetMeasure4 input {
  width: 30px;
}
#SetMeasure3 {
  position: absolute;
}
#SetMeasure4 {
  position: absolute;
}
.BannerWarp-config {
  position: relative;
  min-height: 800px;
}
#measurementscanvas {
  background-color: #ccc;
}

.choicediv0 {
  position: absolute;
  z-index: 10;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #ccc;
  width: 800px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  animation: slide-up 0.5s !important;
}
.choicediv3 {
  position: absolute;
  z-index: 10;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #ccc;
  width: 800px;
  height: 200px;
  display: flex;
  justify-content: space-around;
  animation: slide-up 0.5s !important;
}

.choicediv1 {
  position: absolute;
  z-index: 10;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #ccc;
  width: 800px;
  height: 200px;
  display: flex;
  justify-content: space-around;
}

.choicediv2 {
  position: absolute;
  z-index: 10;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #ccc;
  width: 800px;
  height: 200px;
  display: flex;
  justify-content: space-around;
}

.choicediv button,
.choicediv2 button {
  width: 100%;
}
.slidee {
  position: absolute;
  z-index: 10;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: #ccc;
  width: 800px;
  height: 200px;
  display: flex;
  justify-content: space-around;

  animation: 0.5s slide-up ease-in-out;
}

@keyframes slide-up {
  from {
    top: 1800px;
  }
  to {
    top: 70%;
  }
}
@keyframes Appear {
  from {
    width: 0px;
    opacity: 0;
    height: 100%;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

.step1 {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  flex-direction: column;
}
.project-choice {
  display: flex;
}
.step1-header {
  padding-bottom: 50px;
}

.project-choice div:first-child {
  width: 300px;
}

.step1 button {
  width: 250px;
  height: 150px;
  font-size: 32px;
  margin-left: 30px;
  background-color: #04468c;
  color: white;
  box-shadow: 3px 7px 14px black;
}

.step1 button:hover {
  transform: scale(1.1);
  opacity: 0.8;
  cursor: pointer;
  transition: 0.3s;
}

.Step-count {
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: relative;
}

.hrfill {
  width: 14%;
  margin-top: 25px;
}
.hrfillen {
  width: 5%;
  margin-top: 25px;
}

.Step-count button {
  border-radius: 50%;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #04468c;
  color: white;
  border: white 1px solid;
  font-size: 28px;
}

.Step-count button:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 0.3s;
  opacity: 0.8;
}

.Config-selects {
  padding-top: 50px;
  padding-bottom: 50px;
}
#confighead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  box-shadow: 3px 5px 11px black;
}
#confighead img {
  margin-right: 50px;
}

.BannerWarp-config {
  max-width: 1500px;
}

.active {
  background-color: red !important;
}
.config-button {
  width: 250px;
  height: 50px;
  font-size: 22px;
  font-weight: 800;
  background-color: white;

  color: black;
  box-shadow: 3px 7px 15px black;
}

#finaltable {
  width: 100%;
}

#finaltable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.tablehead th {
  font-size: 20px;
  text-align: left;
  padding-bottom: 10px;
  padding-top: 10px;
}

#showtable {
  position: relative;
  padding: 10px 30px 10px 40px;
  font-size: 18px;
  margin-top: 20px;
  background-color: #04468c;
  color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

#showtable:hover {
  opacity: 0.9;
  cursor: pointer;
  transition: 0.3s;
}
#showtable span {
  position: absolute;
  font-size: 32px;
  left: 15px;
  top: 0px;
  font-weight: 600;
}

.crosscard {
  max-width: 250px;
  margin: 10px;
  border: 1px solid #ccc;
  height: 378px;
  box-shadow: 3px 7px 13px black;
}
.crosscard a {
  display: flex;
  text-decoration: none;
  color: #333;
  flex-direction: column;
  justify-content: center;
}

.crosscard h5 {
  background-color: #04468c;
  color: white;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 15px;
  border: none;
  font-size: 22px;
}

.Crossproducts {
  display: flex;
  justify-content: center;
}
.titel-desc {
  display: flex;
  justify-content: center;
  width: 100%;
}
.titel-desc img {
  width: 50%;
}
.titel-desc div {
  width: 50%;
  background-color: #ccc;
}

.titel-desc div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.titel-desc div button {
  background-color: #04468c;
  color: white;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
  border-radius: 15px;
  border: none;
  font-size: 22px;
  margin-top: 30px;
  width: 300px;
}
.titel-desc div p {
  width: 300px;
}

#finalresult {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
}
#finalresult tr td:last-child {
  text-align: right;
}
#gesamtpreis {
  border-bottom: 2px solid #333;
}

.weiter-zurueck-buttons {
  display: flex;
  justify-content: right;
  margin-top: 30px;
  margin-bottom: 30px;
}
.weiter-zurueck-buttons button:hover {
  transform: scale(1.1);
  opacity: 0.9;
  cursor: pointer;
  transition: 0.3s;
}

#zurueck2,
#zurueck3,
#zurueck4,
#zurueck5 {
  margin-right: 20px;
}

#zurueck5 img {
  transform: rotate(180deg);
  margin-right: 10px;
}

.noClick {
  pointer-events: none;
  background-color: #f4f4f4 !important;
  color: black !important;
  opacity: 0.8;
  border: 1px solid #444 !important;
}
#measures {
  position: relative;
}
#newdrawing {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 20px;
  font-size: 18px;
  padding: 5px 10px 5px 10px;
}
#newdrawing span {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}
.inactivebtn {
  pointer-events: none;
  background-color: #cccfd2;
  opacity: 0.5;
}

.activebtn {
  border: 3px solid maroon;
  box-shadow: 3px 8px 14px black;
}
#modelcanvas {
  height: 801px !important;
  width: 750px !important;
  border: 1px solid #ccc;
}
.config-btnline {
  display: flex;
  justify-content: center;
}

.config-input-line {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

#set2,
#set3,
#set4,
#set2ip,
#set3ip {
  margin-left: 15px;
  background-color: white;
  cursor: pointer;
}
#set4 {
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  cursor: pointer;
}
#set1 {
  cursor: pointer;
}
#gray5,
#anrtz5,
#black5,
#brano5,
#polished5,
#brushed5,
#satin304,
#satin316 {
  position: relative;
}
.hidebtn {
  display: none !important;
}
.Colorswaps {
  display: flex;

  gap: 16px;
}
.movebtn {
  margin-top: -50px;
}
#colordescription1,
#colordescription2,
#colordescription3 {
  width: 200px;
  text-align: center;

  font-size: 13px;
  display: flex;
  margin-left: 8px;
  line-height: 0.97;
  color: #001020;
}
#set1:hover,
#set2:hover,
#set3:hover,
#set4:hover {
  transform: scale(1.05);
  transition: 0.3s;
  opacity: 0.95;
}
.Headline-configurator {
  margin-top: 32px !important;
  font-size: 18px !important;
}

#set2ip,
#set1ip,
#set3ip {
  width: 200px;
}

.Measures-config-render {
  display: flex;
}

.Step5try {
  display: flex;
  position: relative;
  justify-content: space-between;
}
#modelcanvas2 {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  background: #f2f3f4;
  max-height: calc(100vh - 72px);
}
.Selects-configu {
  max-width: 312px;
}
.Configurator-final-text {
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
#yourconfiguredrailing h3 {
  font-size: 14px;
  color: #001020;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}
#yourconfiguredrailing {
  background-color: white;
  padding: 16px 24px;
}
.Configurator-final-text-subhead {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
}
.Selects-configu button {
  background-color: white;
  color: #001020;
  border: 1px solid #ccc;
}
.Configurator-final-text-text {
  font-size: 13px;
  line-height: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0;
}
.Configurator-step7-checkmark-wrap {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 16px;
}
#Configurator-final-checkmark {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #0c9400;
}
#yourconfiguredrailing {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -30px;
  margin-top: -30px;
}
#resp-menu {
  display: none;
}
#resp-menu button {
  background-color: transparent;
  border: none;
}
.rotation {
  transform: rotate(90deg);
  transition: 0.2s;
}

.Selects-configu button:hover {
  transition: 0.1s;
  transform: scale(1);
  opacity: 0.8;
  cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#handlaufswitch {
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Step5try h3 {
  font-size: 22px;
}

#catbutton1 {
  background-color: white;
  color: #333;
  float: left;
  width: 50%;
  margin-left: 0px;
  box-shadow: none;
}
#catbutton2 {
  background-color: white;
  color: #333;
  float: right;
  width: 50%;
  margin-left: 0px;
  box-shadow: none;
}

#catbutton1:hover,
#catbutton2:hover {
  transform: scale(1);
  font-weight: bolder;
  background-color: white;
}

.activebord {
  border-left: 1px #777 solid;
  border-top: 1px #777 solid;
  border-right: 1px #777 solid;
  border-bottom: 0px;
}
.nonactiveboard {
  color: #999 !important;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 1px #777 solid;
}
.activebtn1 {
  background-color: rgb(207, 64, 64) !important;
  border: 3px solid white;
}

.linemeasureinputs {
  width: 60px;
}

#newercanvas {
  width: 100%;
  height: 600%;
  background-color: #04468c;
}

#canvasreplacement {
  background-color: #ccc;
  height: 750px;
  position: relative;
}
.improvedmeasures,
.improvedmeasures3 {
  position: absolute;
  z-index: 30;
  width: 35px;
  background-color: white;
  border: none;
}
.improvedmeasures {
  background-color: transparent;
  pointer-events: none;
}
.improvedmeasures3 {
  width: 70px;
  background-color: transparent;
}
.cornerstuff,
.cornerstuff2,
.cornerstuff3 {
  position: absolute;
  z-index: 300;
  margin-left: -55px;
  margin-top: 10px;
  display: flex;
  box-shadow: 3px 5px 11px #333;
}
.cornerstuff2 button {
  width: 55px;
  height: 55px;
  overflow: hidden;

  cursor: pointer;
  border: 1px solid black;
}
.cornerstuff button {
  width: 55px;
  height: 55px;
  overflow: hidden;
  background: url('http://st2.depositphotos.com/4196725/6756/i/450/depositphotos_67569405-green-grass-texture-or-background.jpg');
  transform: rotate(180deg);
  cursor: pointer;
  border: 1px solid black;
}
.cornerstuff3 button {
  width: 55px;
  height: 55px;
  overflow: hidden;

  transform: rotate(180deg);
  cursor: pointer;
  border: 1px solid black;
}

.cornerstuff button:hover {
  opacity: 0.9;
}
.cornerstuff button svg path {
  fill: #402f1d;
}
.cornerstuff button:last-child svg path {
  fill: #7cb06d;
}
.cornerstuff button:last-child {
  background: url('https://strapiwmc4hstore.blob.core.windows.net/website-assets/Holztexture.jpg');
  margin-left: 5px;
}
.cornerstuff3 button:last-child {
  margin-left: 5px;
}
#canvasreplacement:hover {
  cursor: pointer;
}
.improvedmeasures::placeholder {
  color: red;
}
.improvedmeasures2 {
  position: absolute;
  z-index: 30;
  width: 60px;
  border: none;
  background-color: transparent;
}
.improvedmeasures2::placeholder {
  color: #111;
  z-index: 300;
}

.circless:hover {
  cursor: grab;
  background-color: #2196f3;
  fill: black;
  border: #333 1px solid;
  z-index: 40;
}

.config-btnline-wrap {
  position: relative;
}
.config-btnline {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 200;
  width: 100%;
  background: white;
  left: 50%;
  border-bottom: 1px solid #ccc;

  transform: translate(-50%);
  padding-bottom: 60px;
  padding-top: 30px;
}
.draweffect {
  cursor: pointer;
}

.line-choice {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.line-choice div:last-child {
  margin-left: -20px;
}

.line-choice div:nth-child(2) {
  margin-left: -20px;
}

#gpathid {
  background-color: red !important;
}

#definecorners {
  background-color: #04468c;
  color: white;
  font-weight: bolder;
  padding: 5px 15px 5px 15px;
  border: none;
  cursor: pointer;
  position: absolute;
  margin-top: -8px;
}
#drawingstop {
  background-color: #04468c;
  color: white;
  font-weight: bolder;
  padding: 5px 15px 5px 15px;
  border: none;
  cursor: pointer;
  margin-top: -8px;
  position: absolute;
}

#drawingcont {
  background-color: #04468c;
  color: white;
  font-weight: bolder;
  padding: 5px 15px 5px 15px;
  margin-right: 5px;
  margin-top: -5px;
  cursor: pointer;
  color: black;
  box-shadow: 3px 3px 8px black;
  float: right;
}
#backtomeasure {
  background-color: #04468c;
  color: white;
  font-weight: bolder;
  padding: 0px 5px 0px 5px;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  color: black;
  box-shadow: 3px 3px 8px black;
  float: right;
}

#definecorners:hover {
  transform: scale(1.1);
  opacity: 0.9;
  transition: 0.3s;
}
.activebtn3 {
  border: 5px solid maroon !important;
}

#deletepoint {
  float: right;
  margin-right: 5px;
  margin-top: -5px;
  cursor: pointer;
  background-color: white;
  color: black;
  box-shadow: 3px 3px 8px black;
}
#buttonlinemeasures {
  background-color: #ccc;
  height: 53px;
  margin-top: -4px;
}

#modelcanvas3 {
  height: 250px !important;
}

#scalebtn {
  position: absolute;
  margin-top: 300px;
}
#leftoverdiv {
  display: flex;
}

/*NEU!!*/

.Step5try {
  display: flex;
  justify-content: center;
  border-top: 1px solid #f2f3f4;
  background-color: #eee;
  min-height: calc(100vh - 80px);
}

.Selects-configu {
  width: 100%;
  right: 0px;
  background: white;
  position: sticky;
  /*
    min-height: 700px;
    margin-left: 25px;
    max-width: 380px;
    padding-bottom: 30px;
    padding-right: 25px;*/
}
.step1choice {
  display: flex;
  flex-direction: column;
}
.step1-selection .railing-choice-img {
  width: 100%;
  height: 100%;
  padding: 8px;
}

.step1choice button {
  width: 100%;
  height: 152px;
  border-radius: 4px;
  border: none;
  margin: 0px;

  outline: 1px solid #eee;
}
.forbackdiv {
  display: flex;
  position: absolute;
  bottom: 24px;
  width: calc(100%);
  justify-content: center;
  background-color: white;
  gap: 8px;
  left: 0px;
}
.step1-configurator {
  padding-left: 24px;
  padding-right: 24px;
  min-width: 312px;
  padding-bottom: 84px;
}

.step1-selection h4,
.BaseClampHeadline {
  font-weight: 600;
  font-size: 13px;
  color: #001020;
}
.step3-configurator h4 {
  margin-bottom: 16px;
}
.step3-configurator .step1-selection {
  margin-top: 24px;
}
.step5-configurator h4:last-child {
  margin-top: 24px;
}
.step1-configurator h3 {
  font-size: 18px;
  margin-top: 8px;
  font-weight: 600;
}
.step1-selection {
  margin-top: 24px;
  width: 100%;
}
#activemove img {
  margin-left: -1px !important;
  margin-top: 2px !important;
}
.backbtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 10px !important;
  gap: 8px;
  border-radius: 24px;
  width: 48px !important;
  height: 48px !important;

  /* Light Grey */

  border: none !important;
  background-color: #f2f3f4 !important;
}
.backbtn svg {
  width: 20px;
  height: 20px;
}

.measurementinputclass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.measurementinputclass label {
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.measurementinputclass label span {
  min-width: 80px;
}
.measurementinputclass label:first-child {
  margin-top: 0px;
}

.measurementinputclass input {
  margin-left: 8px;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background-color: #eee;
  border: none;
}

.measurementinputclass label {
  font-size: 13px;
}
.step3-configurator .step-3-measurements-section {
  margin-top: 40px;
}

.step2config .bluecheck1 {
  right: 8px;
  top: 8px;
}
.bigsize-button span {
  color: #676767;
  font-size: 13px;
  display: flex;
  line-height: 13px;
  align-items: center;
  margin-top: 4px;
}
.bigsize-button svg {
  width: 16px;
  margin-top: 8px;
  height: 16px;
  margin-left: 3px;
}
.bluecheck1 {
  position: absolute;
  right: 13px;
  top: 13px;
  display: none;
}
.first_railing_choice {
  display: flex;
  justify-content: space-between;
}

.activeitem .bluecheck1 {
  display: block;
}

.activeitem {
  position: relative;
  /*border: 2px solid #04468c  !important;*/
  outline: 2px solid #04468c !important;
  outline-offset: 0px;
}
.heightwrap h5 {
  margin-left: 20px;
  font-size: 20px;
}

.heightclass button {
  font-size: 13px !important;
  height: 48px;
  padding-right: 5px;
  padding-left: 5px;
}
.heightclass {
  display: flex;
  justify-content: center;
  gap: 8px;
}
.Sectionwrap {
  float: left;
  width: 100%;
}
.Sectionwrap h5 {
  float: left;
  margin-left: 20px;
  font-size: 20px;
}

.configuratorsteps {
  position: absolute;
  top: 32px;
  left: 50%;
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transform: translateX(-50%);
  background-color: transparent;
}
.ConfiguratorMobileButton {
  display: none;
}
.configuratorsteps-btnrow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 650px;
  margin-top: -10px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
  background-color: white;
}

.configuratorsteps button {
  background-color: transparent;
  border: 0px;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  height: 100%;
}

.configuratorsteps button:last-child::after {
  content: '';
}

.Colorswaps button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

#gray5 {
  background-color: #d8d8d8;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#gray5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #d8d8d8;
}
#satin304 {
  background-color: #d8d8d8;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#satin316 {
  background-color: #d8d8d8;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#satin316 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #d8d8d8;
}
#satin304 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #c0c0c0;
}

#polished5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #d8d8d8;
}
#anrtz5 {
  background-color: #676767;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#anrtz5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #676767;
}
#brano5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #d0d5d9;
}

#brushed5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #d0d5d9;
}
.registercustmenu {
  padding-bottom: 120px;
}
#brano5 {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#black5 {
  background-color: #000000;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0px !important;
}
#black5 div {
  width: 48px;
  height: 48px;
  transform: scale(0.6);
  border-radius: 50%;
  background-color: #000000;
}

#modelcanvas2:hover {
  cursor: grab;
}

#topmont,
#sidemont {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#sidemont {
  margin-top: 8px;
}
.Section-mount {
  display: flex;
  width: 100%;
}
.wallfreeclass {
  display: flex;
}

#firstforbtn {
  padding: 16px 32px;
  gap: 8px;
  width: 264px !important;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;

  background: #04468c;
  border-radius: 24px;
}
.firstforback {
  display: flex;
  width: 100%;

  justify-content: center;
}
.forbtn {
  background-color: #04468c !important;
  color: white !important;
  padding: 16px 32px !important;
  gap: 8px;
  width: 208px !important;
  height: 48px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  margin: 0px !important;
  background: #04468c;
  border-radius: 24px !important;
}
#Measurement1::placeholder,
#Measurement3::placeholder,
#Measurement2::placeholder {
  padding-left: 0px;
  font-size: 13px;
}
.step3-configurator .step5-colorselect {
  margin-top: 40px;
}

#configcuttosizeid,
#configglassid,
#configHasInstaller {
  margin-right: 8px;
}
#HasInstallerCheckboxDiv {
  padding-bottom: 24px;
  margin-top: -8px;
}
#configHasInstaller {
  margin-bottom: -4px;
}
#Measurement1 {
  background-color: #f2f3f4;
  border-radius: 4px;
}
#Measurement2 {
  background-color: #f2f3f4;
  border-radius: 4px;
}
#Measurement3 {
  background-color: #f2f3f4;
  border-radius: 4px;
}
.backbtn img {
  margin-top: 2px;
}
.finalstepclass {
  padding-left: 24px;
  padding-right: 24px;
  min-height: 496px;
}
#configlink {
  color: #04468c;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}
#sendconfigrequest {
  width: 208px;
}
.finalbackfordiv a {
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 24px;
  padding: 16px 32px;
  background-color: #f2f3f4;
  color: #001020;
  text-align: center;
  font-size: 13px;
  margin-bottom: 8px;
  text-decoration: none;
}
.finalbackfordiv button {
  background-color: #04468c;
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 24px;
  font-size: 13px;
  padding: 16px 32px;
  color: #ffffff;
  text-align: center;
}
.finalbackfordiv {
  flex-direction: column;
}
.privatetoggle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.privatetoggle h3 {
  margin-top: 15px;
}
.privatetoggle .switch {
  margin-top: 10px;
}
.contactmenu {
  display: flex;
  max-height: calc(100vh - 650px);
  flex-direction: column;
  min-height: 290px;
  overflow-y: scroll;
  padding-bottom: 100px;
}
#AdjustForBackDiv {
  bottom: 2px;
}
.businesscustomer {
  overflow-y: auto;
}
.businesscustomer select {
  padding-top: 16px;
  padding-bottom: 16px;
}
.businesscustomer textarea {
  padding-top: 16px;
  padding-bottom: 16px;
}
.contactmenu input,
.contactmenu textarea {
  background-color: #eee;
  border: 0px;
  padding: 16px;
  font-size: 13px;
  line-height: 16px;
}

.Configurator-step7-fileupload-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
}

.Configurator-step7-fileupload-div #FormFileBlock-Document-label {
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
}
.Configurator-step7-fileupload-div button {
  padding: 16px 32px;
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
}
.contactmenu textarea {
  height: 96px;
  padding-bottom: 64px;
}
.alreadycustomercheck h4 {
  font-size: 14px;
  font-weight: 600;
  color: #001020;
}
#FormFileBlock-filenametext {
  margin-top: 8px;
  font-size: 14px;
}

.activeitem2 {
  font-weight: 900;
  background-color: transparent;

  border-bottom: 2px solid #04468c !important;
  height: 100%;
  color: #04468c;
}
.configuratorsteps button:hover {
  cursor: pointer;
}

.rotationbox {
  position: absolute;
  background-color: transparent;
  top: calc(100vh - 170px);
  width: 100%;
  left: 30px;
  display: flex;
  gap: 8px;
  justify-content: left;
}
.rotationbox button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #001020;
  background-color: white;
  box-shadow: 0 0 10px 0 #dbdbdb;
  border-radius: 50%;
}
.Configurator-step7-fileupload-message {
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -30px;
}
.rotationbox button::after {
  content: '';
  margin-left: 0px;
}
.activemove {
  background-color: #04468c !important;
  color: white;
}
.articlelist {
  background-color: white;
  z-index: 2;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  padding-left: 48px;
  padding-right: 48px;
  border-left: 1px solid rgba(0, 16, 32, 0.1);
  animation: Appear 300ms;
  padding-top: 20px;

  overflow-y: scroll;
  padding-bottom: 80px;
}
.articlelist::-webkit-progress-inner-element {
  background-color: transparent;
}
#SvgIndividualComponentsRotation {
  transform: rotate(-180deg);
  transition: 0.35s;
}
#showProductsConfigurator {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #04468c;
}
#closeproductlist {
  background-color: transparent;
  border: none;
  font-size: 22px;
}
.PleaseEnterValues {
  border: 1px solid maroon;
  transform: scale(1.1);
  transition: 0.4s;
}
.articlelist-wrap {
  left: 0px;
  top: 0px;
  z-index: 52;
}
.configlistlink {
  background-color: #04468c;
  padding: 21px 9px 5px 6px;
  cursor: pointer;
  border-radius: 10px;
}
.configlistlink:hover {
  opacity: 0.9;
  transform: scale(1.05) !important;
  transition: 0.3s;
}
.articletable {
  width: 100%;
  font-size: 14px;
  border-spacing: 0px 8px;
}
.articletable tr:first-child {
  font-weight: bold;
}
.articletable tr:first-child td {
  font-weight: bold;
  padding-right: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
}
.articletable tr:first-child {
}

.measurementinputclass input::-webkit-outer-spin-button,
.measurementinputclass input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.measurementinputclass input[type='number'] {
  -moz-appearance: textfield;
}
.articletable tr td {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 40px;
}
.articlelist-inner-header {
  padding-top: 32px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.articletable tr td:last-child {
  padding-right: 0px;
}

.table_amount {
}
.hide {
  display: none !important;
}
.inoutchoice {
  display: flex;
}
.bluecheck2 {
  top: -5px;
  right: 2px;
}
.Configurator-checkbox-step3-top .step-3-hidden-i {
  display: none;
}
.Configurator-step3-checkbox-div {
  display: flex;
  align-items: center;
}
#step-3-hidden-i:hover {
  position: relative;
  cursor: pointer;
}
#step-3-hidden-i:hover .step-3-hidden-i {
  display: block;
  position: absolute;
  background-color: white;
  margin-top: 20px;
  z-index: 300;
  padding: 16px;
  width: 200px;
  margin-left: -150px;
}
.finalstepclass {
}

.step6select .forbackdiv {
  bottom: 0px;
  padding-top: 4px;
  padding-bottom: 16px;
}
.overview-table {
  display: grid;
  grid-template-columns: 60% 1fr;
  margin-top: 16px;
  margin-bottom: 24px;
}
.overview-table p {
  text-align: start;
}
#showproductlist {
  border: none;
  font-size: 14px;
  color: #04468c;
  font-weight: 600;

  margin-left: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.overview-table h4,
.overview-table p {
  color: #001020;
  margin-top: 8px;
  font-size: 14px;
}
.overview-table h4 {
  font-weight: 600;
}
.privatepublicclass-inner {
  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

#configurator-already-customer-button {
  border: none;
  margin-bottom: 24px;
  border-bottom: 1px solid #001020;
}
.privatepublicclass label {
  display: flex;
  min-width: 96px;

  align-items: center;
}
.privatepublicclass label span {
  margin-left: 8px;
  margin-top: 0px;
  width: 100%;
  font-size: 14px;
}
.privatepublicclass label:last-child {
  margin-left: 24px !important;
}
.alreadycustomercheck {
  margin-top: 24px;
}
.alreadycustomercheck .privatepublicclass-inner {
  margin-top: 16px;
  margin-bottom: 8px;
  padding-top: 0px;
  padding-bottom: 16px;
  border-top: 0px solid rgba(0, 0, 0, 0.05);
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
}
.spacer {
  padding-left: 5px;
  width: 10px;
  margin-top: 5px;
}
.contactmenu label {
  margin-bottom: 8px;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
}
.contactmenu input::placeholder,
.contactmenu textarea::placeholder {
  padding-left: 8px;
}

.contactmenu label:first-child {
  margin-top: 0px;
}

.configurator-wrap {
  position: relative;
  width: 100%;
  background-color: #f2f3f4;
  display: flex;
  justify-content: center;
}
#showproductlist span {
  font-size: 10px;
  padding-left: 30px;
  display: flex;
  margin-right: 5px;
}
.radioinput:checked {
  background-color: #04468c;
}
.radioinput {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.radioinput2:checked {
  background-color: #04468c;
}
.radioinput2 {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.Handrailselect-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Handrailselect {
  width: 100%;
}
.Handrailselect h3 {
  font-size: 16px;
}

.Handrailselect .inoutchoice {
  display: block;
}
.Handrailselect .inoutchoice button {
  width: 100%;
  margin-left: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  height: 48px;
}
.Handrailselect .inoutchoice button {
  margin-top: 8px;
}
.Handrailselect .inoutchoice button:first-child {
  margin-top: 0px;
}
.configuratorsteps button {
  position: relative;
}
.configuratorsteps button span {
  position: absolute;
  font-size: 16px;
  color: #f2f3f4;
  margin-top: -2px;
  right: 0px;
}

.bigsize-button h4 {
  margin-top: 8px;
}
.usedbutton {
  border-bottom: 2px solid #04468c !important;
}

.step-1-hidden-i {
  display: none;
}
.choice1,
.choice2,
.choice3 {
  position: relative;
}
.choice1:hover .step-1-hidden-i {
  display: block;
  position: absolute;
  z-index: 300;
  min-width: 220px;
  min-height: 90px;
  background-color: white;
  right: 10px;
  top: 30px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
}
.choice2:hover .step-1-hidden-i {
  display: block;
  position: absolute;
  z-index: 300;
  min-width: 220px;
  min-height: 90px;
  background-color: white;
  right: 10px;
  top: 30px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
}
.choice3:hover .step-1-hidden-i {
  display: block;
  position: absolute;
  z-index: 300;
  min-width: 220px;
  min-height: 90px;
  background-color: white;
  right: 10px;
  top: 30px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.05);
}
.Configurator-checkbox-step3 {
  margin-top: 24px;

  font-size: 14px;
}
#Configurator-size-error-warning {
  font-size: 10px;
  color: #ff9494;
  margin-top: 8px;
}
.lockedsendbtn {
  pointer-events: none;
  background-color: #667079 !important;
  opacity: 0.7;
}
.Configurator-checkbox-step3-top {
  margin-top: 16px;

  font-size: 14px;
}
.Configurator-checkbox-step3-top input,
.Configurator-checkbox-step3 input {
  margin-left: 8px;
  width: 18px;
  height: 18px;
}
.privatepublicalreadycust2 {
  position: relative;
}
.Configurator-checkbox-step3,
.Configurator-checkbox-step3-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Configurator-checkbox-step3 input::before {
  font-size: 8px;
}
.Configurator-checkbox-step3-top input[type='checkbox']:enabled:checked,
.Configurator-checkbox-step3 input[type='checkbox']:enabled:checked {
  accent-color: #04468c;
  border-radius: 5px;
}

.AdjustPositionOfCanvas {
  position: absolute !important;
}

.contactmenu label span {
  color: #667079;
  font-weight: 500;
}
.Configurator-CustType-headline {
  position: absolute;
  font-size: 14px !important;
  margin-top: -32px;
}
.step3-configurator .Configurator-MountBlock {
  margin-top: 40px;
}
.ConfigHoveText p {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 8px;
}
.underground-choice-class button p span {
  color: #667079;
  padding-left: 8px;
  font-size: 12px;
}
#ConfigSelectGlassThiccness {
  width: 100%;
}
#ConfigSelectGlassThiccness select option {
  padding-top: 8px;
  margin: 8px;
  padding-bottom: 8px;
}
#ConfigSelectGlassThiccness select {
  width: 100%;
  height: 48px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

#hiddenmount {
  display: none;
}
.baseclampsglassdistribution .inoutchoice {
  display: flex;
  justify-content: space-between;
}
.baseclampsglassdistribution .inoutchoice img {
  width: 120px;
}
.baseclampsglassdistribution .inoutchoice button {
  border-radius: 4px;
}
#step1ScrollSupport {
  display: none;
}

@media (min-width: 1024px) {
  .body-scrollable-content {
    overflow-y: hidden !important;
  }
  .scrollable-content {
    overflow: hidden;
    max-height: 100vh;
    position: relative;
    padding-bottom: 64px;
  }

  .Step1Scroll {
    width: 292px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .Step1Scroll h3 {
    max-width: calc(100% - 16px);
    margin-bottom: 32px;
  }

  .firstforback {
    width: 312px;

    position: fixed;
    left: calc(100% - 312px);
    justify-content: right;
    padding-right: 48px;
    padding-top: 12px;
    padding-bottom: 18px;
    bottom: 0px;
    align-items: center;
  }
  #step1ScrollSupport {
    display: block !important;
    padding-top: 180px;
    height: 16px;
    width: 312px;
  }
}
@media (max-height: 900px) {
  .FrenchBalconySystemCard img {
    max-height: 95px;
  }
  .FrenchBalconySystemCard button {
    max-height: 95px !important;
  }
}

@media (min-height: 1100px) {
  .scrollable-content {
    padding-top: 0px !important;
    max-height: 1300px;
  }
}

#greyoutglass {
  pointer-events: none;
  opacity: 0.3;
}
.BaseClampSystem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
.BaseClampSystem button {
  height: 48px;
  font-weight: 600;
}
#inactivesubsystem {
  pointer-events: none;
  opacity: 0.5;
}
#Confighovertext-baseclamps {
  position: absolute;
  left: -200px;
  display: none;
  z-index: 300;
  max-width: 200px;
  background-color: white;
  padding: 16px;
  border: 1px solid #f2f3f4;
  box-shadow: 1px 3px 7px #667079;
}
#QrailingConfiguratorSideMenu {
}
.Baseclampsystem-hover span {
  position: relative;
}
.Baseclampsystem-hover span:hover {
  cursor: pointer;
}
.Baseclampsystem-hover span:hover #Confighovertext-baseclamps {
  display: block;
  cursor: pointer;
}
.Baseclampsystem-hover {
  display: flex;

  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.Baseclampsystem-hover span {
  display: flex;
  align-items: center;
}
#HeadlineSystem {
  margin-bottom: 0px;
}

.Step1Scroll .ConfiguratorDesktopButton {
  margin: 1px 1px 24px 1px !important;
  width: calc(100% - 16px);
}
.Step1Scroll .ConfiguratorDesktopButton button {
  margin-left: 1px;
}
.frenchbalconyinput {
  align-items: flex-start;
}
.windowamounts {
  display: flex;

  gap: 16px;
  margin-top: 16px;
}
.windowamounts button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f2f3f4;
  border: 1px solid #f2f3f4;
  font-size: 14px;
  font-weight: 600;
}
.PlusMinusButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f2f3f4;
  border: 1px solid #f2f3f4;
  font-size: 14px;
  font-weight: 600;
}
.PlusMinusButtonWrap {
  display: flex;
  justify-content: center;
}
.FrenchBalconyInnerSpan {
  font-size: 8px;
  white-space: nowrap;
  margin-left: 5px;
  font-weight: 600;
  color: #667079;
}

.configuration-downloads {
  padding: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.configuration-downloads > h5 {
  font-size: 14px;
}
.FrenchBalconySystemSelect {
  margin-top: 16px;
}
.FrenchBalconyWrapStep4 button {
  max-height: 150px;
}
.FrenchBalconySystemCard {
  margin-bottom: 16px;
}
.PostSelect {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.PostSelect button {
  width: 100%;
  height: 48px;
}
.FrenchBalconySystemCard h3 {
  text-align: left;
  font-size: 14px;
  margin-top: 8px;
}
.FrenchBalconySystemCard img {
  width: 100%;
  border-radius: 8px;
}
.FrenchBalconySystemCard button {
  border-radius: 8px;
  max-height: 149px;
}
.configuration-downloads-inner {
  margin-top: 8px;
  margin-bottom: 12px;
}
.qline-select-infill {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.qline-select-infill button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  height: 48px;
}
.qline-select-infill button img {
  height: 30px;
  width: 30px;
}
.configuration-downloads-item {
  position: relative;
  padding: 10px 8px 6px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.configuration-downloads-item:hover {
  background-color: #f2f3f4;
}
.configuration-downloads-item:active {
  background-color: #d8d8d8;
}
#nextStep1btn {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020 !important;
}
#Step1Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
#Step2Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
#Step3Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
#Step4Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
#Step5Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
.ColorSelectFb {
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
.ColorSelectFb button {
  align-items: center;
  border-radius: 4px;
}
#Step6Navbar {
  pointer-events: all;
  border-bottom: 0px;
  color: #001020;
  opacity: 1;
}
.FrenchBalconyWrapStep4 {
  padding-left: 16px;
  min-height: unset;
  padding-right: 16px;
}

.configuration-downloads-item > .icon {
  position: absolute;
  padding: 10px 12px 6px 12px;
  top: 0;
  right: 0;
  bottom: 0;
}
.configuration-downloads-item > .text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 220px;

  display: inline-block;
  font-size: 14px;
  height: 16px;
}
.configuration-downloads-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.finalstepclass .configuration-downloads {
  padding: 24px 0 0 0;
}

.hide-element {
  display: none;
}

.ConfiguratorModulePopupHead {
  white-space: nowrap;
}
.ConfiguratorModulePopupHead button {
  padding: 4px 24px 4px 8px;
  background-color: white;
  border: 1px solid #667079;
  color: #667079;
  border-radius: 24px;
  position: relative;

  cursor: pointer;
  margin-left: 32px;
}
.ConfiguratorModulePopupHead button:hover {
  opacity: 0.8;
  transition: 0.3s;
}
.ConfiguratorModulePopup td:first-child {
  padding-left: 32px;
}
.CustomRalColor {
  display: flex;
  align-items: center;
  gap: 8px;
}
.CustomRalColor input {
  height: 32px;
  padding-left: 16px;
  border: none;
  background-color: #eee;
}
.CustomRalColor input::placeholder {
  padding-left: 8px;
}
.error-ral {
  border: 1px solid maroon !important;
}
#RalColorInput {
  max-width: 96px;
}
.ConfiguratorModulePopup {
  animation: Appear 500ms;
}
#CofiguratorSvgRotate {
  top: 5.3px;
  transform: rotate(270deg);
  transition: 0.1s;
}
.ConfiguratorModulePopupHead button svg {
  transform: rotate(90deg);
  position: absolute;
  right: 9px;
  top: 6px;
}

.FrontOrInset {
  width: 100%;
}
.FrontOrInset button {
  padding: 8px 16px;
  background-color: #001020;
  color: White;
  border-radius: 24px;
}
.FrontOrInset button:last-child {
  margin-left: 8px;
}
.HandrailFrenchBalcony {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: column;
}
.HandrailFrenchBalcony button {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HandrailFrenchBalcony button img {
  width: 100%;
  height: 100%;
  max-width: 120px;
}
.HandrailFrenchBalcony button p {
  margin-left: 8px;
  font-weight: 600;
}
.FrenchBalconyCustomGlass {
  margin-top: 24px;
}
.FrenchBalconyCustomGlass label {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
  font-size: 14px;
  padding-top: 16px;
  border-top: 1px solid #f2f3f4;
}
.FbCustomGlassWrap input[type='checkbox'] {
  margin-left: 0px;
}
.FrenchBalconyCustomGlass p {
  font-size: 14px;
  color: #667079;
}
.FrenchBalconyCustomGlass input {
  margin-left: 8px;
  max-width: 100px;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background-color: #eee;
  border: none;
}
.FineTuningStep5 {
  padding-top: 16px;
  font-size: 32px;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f2f3f4;
}

@media (min-width: 1350px) {
  #Configurator-Extended-Step .Step1Scroll {
    width: 640px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr; /* Adjust rows to fit content 
    padding-right: 16px;*/
    grid-template-areas:
      'headline headline'
      'content4 content2'
      'content3 content1';
  }
  #Configurator-Extended-Step button {
    max-width: 280px;
    width: 280px;
  }
  #Configurator-Extended-Step .Step1Scroll h3 {
    grid-area: headline;
  }
  #Configurator-Extended-Step .bigsize-button:nth-child(1) {
    grid-area: content1;
  }

  #Configurator-Extended-Step .bigsize-button:nth-child(2) {
    grid-area: content2;
  }
  #Configurator-Extended-Step .bigsize-button:nth-child(3) {
    grid-area: content3;
  }

  #Configurator-Extended-Step .bigsize-button:nth-child(4) {
    grid-area: content4;
  }

  #Configurator-Extended-Step {
    max-width: 640px;
    width: 100%;
  }
  .step1-select-width {
    max-width: 640px;
    width: 100%;
    right: 0px;
  }
  .step-1-changed {
  }
}
@media (max-height: 875px) and (min-width: 1250px) {
  .step1choice button {
    height: 125px;
    width: 227px !important;
  }
}
@media (max-height: 875px) {
  .Handrailselect .inoutchoice button {
    height: 38px;
  }
  .PostSelect button {
    height: 38px;
  }
  .qline-select-infill button {
    height: 38px;
  }
  .step1choice button {
    height: 125px;
  }
  .bigsize-button h4 {
    margin-top: 0px;
  }
  .Configurator-checkbox-step3-top {
    margin-top: 12px;
  }
  .finalstepclass .configuration-downloads {
    padding: 14px 0px 0px 0px;
  }
  .privatepublicclass-inner {
    padding-top: 14px;
  }
}
@media (max-height: 875px) and (max-width: 1320px) {
  .step1choice button {
    height: unset !important;
  }
}
.PostSelect button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
}
#ConfiguratorStep4-Plates .ConfigHoveText {
  display: none;
}
#step-3-hidden-i-plates {
  position: relative;
}
#step-3-hidden-i-plates:hover .ConfigHoveText {
  display: block !important;
  opacity: 1;
  background-color: white;
  z-index: 3;
}

#step-3-hidden-i-plates:hover svg {
  opacity: 0;
}
#ConfiguratorStep4-Plates {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#TapAndDrillPosts input,
#TapAndDrillHandrail input {
  margin-left: 0px;
  margin-right: 16px;
}

.QlineHeadline {
  font-size: 13px;
  font-weight: 600;
  color: #001020;
  margin-bottom: 16px !important;
}
.QlineColorchoice {
  margin-top: 0px !important;
}
.QlineColorSelection {
  margin-top: 16px !important;
}

#ConfigSelectInfillQline h4 {
  margin-bottom: 8px !important;
}
#QlineCutToSize input {
  margin-left: 0px;
  margin-right: 16px;
}
#hovertextRailingDisclaimer {
  position: absolute;
  font-size: 14px;
  color: #667079;
  padding: 8px;
  z-index: 1;
  background-color: #fff;
  max-width: 350px;
  margin-right: -100px;
}
#HovertextRailingEnter:hover #hovertextRailingDisclaimer {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ConfiguratorPrivateForm-wrap {
  position: fixed;
  top: 73px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: white;
  z-index: 2;
  animation: moveInTop 0.3s ease-out;
}
.ConfiguratorPrivatForm-inner {
  display: flex;

  justify-content: center;
}
@keyframes moveInTop {
  0% {
    transform: translateY(-500px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.ConfiguratorPrivateForm-left-inner {
  display: flex;
  flex-direction: column;
}

.ConfiguratorPrivateForm-left-inner .overview-table {
  padding-left: 48px;
}

.ConfigPrivateForm-NameRow {
  display: flex;
  gap: 16px;
}
.ConfiguratorPrivateForm-left img {
  width: 358px;
}

.ConfiguratorPrivateForm-right .contactmenu {
  max-height: unset;
  overflow-y: unset;
  padding-bottom: 24px;
}
.ConfiguratorPrivateForm-right {
  width: 50%;
  padding-top: 72px;
  padding-left: 42px;
  padding-bottom: 92px;
}
.ConfigPrivateForm-Rowentity {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ConfigPrivateForm-Rowentity input {
  width: 100%;
}
.ConfigPrivateForm-NameRow {
  padding-top: 8px;
  padding-bottom: 8px;
}
.ConfigPrivateForm-FirstRow {
  display: flex;
  flex-direction: column;
}
.ConfigPrivateForm-FirstRow select {
  max-width: 150px;
  height: 32px;
}
.ConfiguratorPrivateForm-right-inner {
  display: flex;
  flex-direction: column;
  max-width: 729px;
  padding-right: 24px;
  width: 100%;
}
.Configurator-step10-Text-top h2 {
  font-size: 32px;
  margin-bottom: 15px;
}
.Configurator-step10-Text h2 {
  font-size: 32px;
  margin-bottom: 15px;
}

.configurator-step11-cards .howwework2-card span {
  color: #cccfd2;
}
.configurator-step11-cards .howwework2-cards .howwework2-card:nth-child(3) {
  border-left: 3px solid #cccfd2;
}
.configurator-step11-cards .howwework2-cards .howwework2-card:nth-child(1) {
  border-right: 3px solid #cccfd2;
}
.ConfigPrivateForm-Streetrow div:last-child {
  flex-basis: 30%;
}

.ConfigPrivateForm-Cityrow div:first-child {
  flex-basis: 30%;
}
.Configurator-step10-Text-top p,
.Configurator-step10-Text p {
  font-size: 14px;
  color: #001020;
}
.Configurator-step10-Text-top {
  margin-bottom: 64px;
}
.ConfiguratorPrivateForm-left {
  padding-top: 72px;
  padding-bottom: 92px;
  width: 50%;

  padding-right: 32px;

  display: flex;
  justify-content: flex-end;
  background-color: #f2f3f4;
}
.ConfiguratorPrivatForm-LastStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}
.ConfiguratorPrivatForm-LastStep .text {
  text-decoration: underline;
}
.ConfigPrivateForm-Step9BtnsLine {
  display: flex;
  gap: 8px;

  max-width: 729px;
  justify-content: flex-end;
}
.ConfigPrivateForm-Step9BtnsLine button {
  padding: 8px 16px;
  border-radius: 32px;
  margin-right: 24px;
  border: none;
  background-color: #04468c;
  color: white;
}
.ConfigPrivateForm-Step9BtnsLine button:first-child {
  background-color: #f2f3f4;
  border-radius: 50%;
  margin-right: 0px;
  width: 48px;

  height: 48px;
}
.ConfigPrivateForm-Step9BtnsLine button:last-child {
  max-width: 208px;
  width: 100%;
}
.ConfigPrivateForm-Headline h2 {
  padding-bottom: 16px;
  font-size: 32px !important;
  font-weight: 600;
  line-height: 40px;
}
.Configurator-step11-final-text {
  font-size: 48px;
  max-width: 648px;
}

.Configurator-step11-FinalHasInstallertext p {
  max-width: 648px;
  font-size: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.Configurator-step11-FinalHasInstallertext {
  display: flex;
  flex-direction: column;
  color: #667079;
  gap: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
  align-items: center;
}
.Configurator-step11-FinalHasInstallertext button {
  padding: 16px 32px;
  border-radius: 32px;
  margin-top: 24px;
  margin-right: 24px;
  width: 100%;
  max-width: 480px;
  border: none;
  background-color: #04468c;
  color: white;
}
.Configurator-step10-Text {
}
.ConfiguratorPrivateForm-wrap .Configurator-final-text {
  border-bottom: 0px;
}
.ConfiguratorPrivateForm-wrap .configuration-downloads {
  border-top: 0px !important;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 64px;
}
.Configurator-step11-icon svg {
  width: 16px;
  margin-right: 8px;
}

.ConfiguratorPrivateForm-wrap .configuration-downloads .configuration-downloads-item {
  border: 0px !important;
  color: #667079;
  padding: 7px 0px 0px 0px !important;
}
.ConfiguratorPrivateForm-wrap #configuration-downloads-custom-overview {
  margin-top: 4px;
}
.Configurator-step11-confilink {
  margin-top: 52px;
  text-align: center;
}
.Configurator-step11-confilink h3 {
  font-size: 16px;
  margin-bottom: 8px;
}
.Configurator-step11-confilink p a {
  font-size: 14px;
  color: #667079;
}
.Configurator-step11-confilink p {
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #667079;
}
.Configurator-step11-confilink p svg {
  width: 16px;
  margin-right: 8px;
  height: 16px;
}
.ConfiguratorPrivateForm-right-inner #ConfigRequestSalutationP {
  padding: 12px;
  padding-right: 32px;
  height: 48px;
  border: 0px;
  background-color: #eee;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow for Safari */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  background: #eee
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw2IDZMMTEgMSIgc3Ryb2tlPSIjMDAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyYW5kIi8+PC9zdmc+')
    no-repeat right 16px center;
  background-size: 10px 8px;
  cursor: pointer;
}
.ConfiguratorPrivateConnect-InstallerLi-linewrap li p {
  color: #667079;
}
#Config-Step10-disabled {
  pointer-events: none;
  background-color: #667079 !important;
  opacity: 0.7;
}

.Configurator-step11-checkmark-error span {
  background-color: darkgoldenrod !important;
}

.Configurator-step11-warning {
  padding: 16px;
  max-width: 648px;
  border-radius: 14px;
  margin-top: 48px;
  background-color: rgba(224, 187, 22, 0.4);
}
.Configurator-step11-warning h5 {
  font-size: 24px;
  margin-top: 9px;
  margin-bottom: 9px;
}
.Configurator-step11-warning h5:first-of-type {
  margin-top: 0px;
}
.Configurator-step11-warning li {
  margin-top: 4px;
  padding-left: 16px;
  list-style: none;
}
.Configurator-step11-warning li::before {
  content: '•';
  padding-right: 8px;
  margin-left: -16px;
}
.Configurator-step11-confilink #configlink {
  margin-top: 16px;
  text-align: left;
}
.Configurator-step11-final-text-error {
  padding-bottom: 0px;
  margin-bottom: 8px;
}

.Configurator-Modal-step10-wrap {
  z-index: 10;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
}
.Configurator-Modal-step10-outer {
  position: absolute;
  padding: 8px;
  max-width: 734px;
  border-radius: 14px;
  left: 50%;
  background-color: white;
  top: 50%;
  transform: translate(-50%, -50%);
}
.Configurator-Modal-step10-text {
  padding: 16px;
  padding-top: 4px;
}
.Configurator-Modal-step10-text h3 {
  font-size: 24px;
  margin-bottom: 16px;
  margin-top: 32px;
}
.Configurator-Modal-step10-text h4 {
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 32px;
}
.Configurator-Modal-step10-text li {
  list-style: none;
  list-style-position: outside;
  padding-left: 16px;
}
.Configurator-Modal-step10-text li::before {
  content: '•';
  margin-left: -16px;
}

.Configurator-Modal-step10-text li span {
  padding-left: 16px;
}
.Configurator-Modal-step10-text p {
  font-size: 16px;
}
.Configurator-Modal-step10-text p:last-of-type {
  margin-top: 32px;
}
.Config-step10-modal-ButtonLine {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 32px;
}
.Config-step10-modal-ButtonLine button {
  padding: 16px 32px;
  border-radius: 24px;
  border: none;
  background-color: #f2f3f4;
}
.Config-step10-modal-ButtonLine button:last-of-type {
  padding: 16px 32px;
  border-radius: 24px;
  color: white;
  background-color: #04468c;
}
.Configurator-Modal-step10-closebtn button {
  border: none;
  background-color: none;
}
.Configurator-Modal-step10-closebtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
  padding-top: 8px;
}
.Configurator-step9-hasinstallerchoice {
  padding-top: 32px;
}
.Configurator-step9-hasinstallerchoice-btns {
  margin-top: 16px;
}

.Configurator-step9-hasinstallerchoice-btns {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
  width: 100%;
}
#ConfigHideCards {
  display: none;
}
.Configurator-step9-hasinstallerchoice-btns input[type='radio'] {
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for Safari */
  -moz-appearance: none; /* Remove default styling for Firefox */
  width: 18px;
  height: 18px;
  border: 2px solid transparent; /* Outer circle border */
  border-radius: 50% !important;
  position: relative;
  cursor: pointer;
}
.Configurator-step9-hasinstallerchoice-btns input[type='radio']:checked {
  border: 3px solid #04468c;
}
.Configurator-step9-hasinstallerchoice-btns input[type='radio']:checked::before {
  content: '';
  width: 12px; /* Inner disc size */
  height: 12px; /* Inner disc size */
  background-color: #04468c; /* Inner disc color */
  border-radius: 50% !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Configurator-step9-hasinstallerchoice-btns label {
  display: flex;
  font-size: 16px;
  gap: 6px;
  margin-top: 0px;
  align-items: center;
}
.Configurator-step10-Text a {
  color: #04468c;
}
